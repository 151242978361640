import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route, Switch,
} from 'react-router-dom';
import * as Sentry from '@sentry/browser';

const Checkout = React.lazy(() => import('./components/Checkout'));
const PaymentLink = React.lazy(() => import('./components/PaymentLink'));
const NotFound = React.lazy(() => import('./components/NotFound'));
const EmbeddedCheckout = React.lazy(() => import('./components/EmbeddedCheckout'));
const EmbeddedCheckoutHandoff = React.lazy(() => import('./components/EmbeddedCheckoutHandoff'));
const EmbeddedCheckoutRetry = React.lazy(() => import('./components/EmbeddedCheckoutRetry'));

const ENABLE_PAYMENT_LINK = (process.env.REACT_APP_ENABLE_PAYMENT_LINK === 'true');

const UUID_V4 = '[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}';
const BANKED_ID = '[a-z0-9]{3,}';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
});

ReactDOM.render(
  <Suspense fallback={<div className="banked-spinner" />}>
    <Router>
      <Switch>
        <Route path={`/(v3)?/:checkoutSessionId(${UUID_V4})/handoff`} component={EmbeddedCheckoutHandoff} />
        <Route path={`/(v3)?/:checkoutSessionId(${UUID_V4})/retry`} component={EmbeddedCheckoutRetry} />
        <Route path={`/v3/:paymentId(${UUID_V4})`} component={EmbeddedCheckout} />
        <Route path={`/:paymentId(${UUID_V4})`} render={props=> {
          const params = new URLSearchParams(props.location.search)
          if(params.get('v3') !== null) {
              return <EmbeddedCheckout {...props} />
          }
          return <Checkout {...props} />;
        }}/>
        { ENABLE_PAYMENT_LINK && <Route path={`/:bankedId(${BANKED_ID})/:amount([0-9]{1,3}.[0-9]{0,2})`} component={PaymentLink} /> }
        { ENABLE_PAYMENT_LINK && <Route path={`/:bankedId(${BANKED_ID})/:amount([0-9]{1,3})`} component={PaymentLink} /> }
        { ENABLE_PAYMENT_LINK && <Route path={`/:bankedId(${BANKED_ID})`} component={PaymentLink} /> }
        { ENABLE_PAYMENT_LINK && (
          <Route
            exact
            path="/"
            component={() => {
              window.location = 'https://banked.com/mobile-app'; return null;
            }}
          />
        )}
        <Route component={NotFound} />
      </Switch>
    </Router>
  </Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
